<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="部门" prop="departmentId">
        <el-select
          v-model="dataForm.departmentId"
          placeholder="请选择部门"
          style="width: 100%"
          clearable
          filterable
          @change="selectDepartment"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门负责人" prop="depatmentChargeId">
        <el-select
          v-model="dataForm.depatmentChargeId"
          clearable
          filterable
          style="width: 100%"
          placeholder="请选择部门负责人"
          @change="depatmentChargeIdChange"
        >
          <el-option
            v-for="item in allEmployeeList"
            :key="item.id"
            :label="item.name + '(' + item.departmentName + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组" prop="teamId">
        <el-select
          v-model="dataForm.teamId"
          placeholder="请选择班组"
          style="width: 100%"
          clearable
          filterable
          @focus="teamFocus"
          @change="selectTeam"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组负责人" prop="teamChargeId">
        <el-select
          v-model="dataForm.teamChargeId"
          clearable
          filterable
          style="width: 100%"
          placeholder="请选择班组负责人"
          @change="teamChargeIdChange"
        >
          <el-option
            v-for="item in allEmployeeList"
            :key="item.id"
            :label="item.name + '(' + item.departmentName + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位负责人" prop="positionChargeId">
        <el-select
          v-model="dataForm.positionChargeId"
          placeholder="请选择负责人"
          style="width: 100%"
          clearable
          filterable
          @focus="employeeFocus"
          @change="selectEmployee"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="职位" prop="zhize">
        <el-input v-model="dataForm.zhize" placeholder="职位"></el-input>
      </el-form-item>
      <el-form-item label="业务" prop="yewu">
        <el-input v-model="dataForm.yewu" placeholder="业务"></el-input>
      </el-form-item>
      <el-form-item label="区域" prop="quyu">
        <el-input v-model="dataForm.quyu" placeholder="区域"></el-input>
      </el-form-item>
      <el-form-item label="设备" prop="shebei">
        <el-input v-model="dataForm.shebei" placeholder="设备"></el-input>
      </el-form-item>
      <el-form-item label="生效时间" prop="effectiveDate">
        <el-date-picker
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="dataForm.effectiveDate"
          type="date"
          placeholder="选择生效时间"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      departmentList: [], // 部门列表
      teamList: [], // 班组列表
      employeeList: [], //员工列表
      allEmployeeList: [],
      dataForm: {
        id: 0,
        departmentId: "",
        departmentName: "",
        teamId: "",
        teamName: "",
        depatmentChargeId: "",
        depatmentChargeName: "",
        teamChargeId: "",
        teamChargeName: "",
        positionChargeId: "",
        positionChargeName: "",
        zhize: "",
        yewu: "",
        quyu: "",
        shebei: "",
        effectiveDate: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        departmentId: [
          { required: true, message: "部门不能为空", trigger: "blur" },
        ],
        teamId: [{ required: true, message: "班组不能为空", trigger: "blur" }],
        depatmentChargeId: [
          { required: true, message: "部门负责人不能为空", trigger: "blur" },
        ],
        teamChargeId: [
          { required: true, message: "班组负责人不能为空", trigger: "blur" },
        ],
        positionChargeId: [
          { required: true, message: "岗位负责人不能为空", trigger: "blur" },
        ],
        effectiveDate: [
          { required: true, message: "生效时间不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取所有员工
    getAllEmployee() {
      this.$http.employee.list({ limit: 99999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.allEmployeeList = res.data.list;
        }
      });
    },
    // 选择班组负责人
    teamChargeIdChange(e) {
      this.dataForm.teamChargeName = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.allEmployeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.teamChargeName = result[0].name;
        }
      }
    },
    // 选择部门负责人
    depatmentChargeIdChange(e) {
      this.dataForm.depatmentChargeName = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.allEmployeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.depatmentChargeName = result[0].name;
        }
      }
    },
    // 选中岗位负责人
    selectEmployee(value) {
      this.employeeList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.positionChargeName = item.name;
        }
      });
    },
    // 岗位负责人选择框获得焦点
    employeeFocus() {
      if (!this.dataForm.teamId) {
        this.$message.error("请先选择班组");
        return;
      }
      this.getEmployee();
    },
    // 获取班组下的所有员工
    getEmployee() {
      this.$http.employee
        .list({ limit: 99999, page: 1, tid: this.dataForm.teamId })
        .then((res) => {
          if (res && res.code == 0) {
            this.employeeList = res.data.list;
          }
        });
    },
    // 选中班组
    selectTeam(value) {
      this.teamList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.teamName = item.name;
          if (item.employee) {
            this.dataForm.teamChargeId = item.employee.id;
            this.dataForm.teamChargeName = item.employee.name;
          }
        }
      });
    },
    // 获取班组列表
    getTeamList() {
      this.$http.team
        .list({ limit: 99999, page: 1, did: this.dataForm.departmentId })
        .then((res) => {
          if (res && res.code == 0) {
            this.teamList = res.data.list;
          }
        });
    },
    // 班组选择框获得焦点
    teamFocus() {
      if (!this.dataForm.departmentId) {
        this.$message.error("请先选择部门");
        return;
      }
      this.getTeamList();
    },
    // 选择部门
    selectDepartment(value) {
      this.departmentList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.departmentName = item.name;
          if (item.employee) {
            this.dataForm.depatmentChargeId = item.employee.id;
            this.dataForm.depatmentChargeName = item.employee.name;
          }
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ limit: 9999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.departmentList = res.data.list;
        }
      });
    },
    init(id) {
      this.getAllEmployee();
      this.getDepartmentList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.gridposition
            .info({ id: this.dataForm.id })
            .then((data) => {
              if (data && data.code === 0) {
                this.dataForm = data.data;
                this.getTeamList();
                this.getEmployee();
              }
            });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.gridposition.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.gridposition.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
